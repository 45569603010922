<template>
    <div class="page-wrapper">
        <section id="intro" class="hero">
            <div class="slides pos1">
                <div class="title-wrap pos1">
                    <h2 class="regular-text" data-inview="fadeInUp" data-delay="200">
                        {{ $t("elmire c’est quoi ?") }}
                    </h2>
                    <div class="line bleu" data-inview="fadeInUp" data-delay="300"></div>
                    <h1 class="title" data-inview="fadeInUp" data-delay="400">
                        {{ $t("Une agence de marketing numérique située au plein coeur de Montréal.") }}
                    </h1>
                </div>

                <div class="title-wrap pos2">
                    <h2 class="regular-text">{{ $t("elmire fait quoi ?") }}</h2>
                    <div class="line vert"></div>
                    <h1 class="title">
                        {{ $t("La promotion de marques, de produits ou de services via les différents canaux et plateformes numériques.") }}
                    </h1>
                </div>

                <div class="title-wrap pos3">
                    <h2 class="regular-text">{{ $t("elmire se démarque comment ?") }}</h2>
                    <div class="line jaune"></div>
                    <h1 class="title">
                        {{ $t("Par sa maîtrise de la spécificité du Québec et l’élaboration de stratégies propulsées par la donnée.") }}
                    </h1>
                </div>
            </div>
            <div class="img-wrap">
                <div class="circle">
                    <div class="rotator pos1">
                        <img src="@/assets/img/circle.svg" alt="Elmire logo" />
                    </div>
                </div>
                <img src="@/assets/img/hero.jpg" class="desktop lazyload" alt="Elmire équipe" />
                <img
                    src="@/assets/img/hero-mobile.jpg"
                    class="mobile lazyload"
                    alt="Elmire équipe"
                />
            </div>
        </section>

        <section id="expertises" class="services-wrap">
            <img src="@/assets/img/half-circle-1.svg" class="circle" alt="Elmire logo" />

            <div class="title-intro">
                <p class="regular-text" data-inview="fadeInUp" data-delay="200">{{ $t("Expertises") }}</p>
                <h2 class="title" data-inview="fadeInUp" data-delay="400">
                    <span v-html="$t('On utilise notre connaissance du Québec<br />pour vous aider à être plus performant.')"></span>
                </h2>
            </div>

            <div class="bloc-img">
                <div class="img" data-inview="revealRight" data-delay="200">
                    <img
                        class="lazyload"
                        data-src="/static-assets/strategies-medias.jpg"
                        alt="Site web"
                    />
                </div>
                <div class="data" data-inview="slideLeft" data-delay="200">
                    <h3 class="regular-text">{{ $t('Publicité en ligne') }}</h3>
                    <h2 class="title">
                        {{ $t('Offrez-vous un service de publicité clé en main avec des experts qui connaissent le Québec.') }}
                    </h2>
                    <p class="regular-text">
                        {{ $t('Peu importe le problème d’affaires à résoudre, nos stratégies médias numériques 360 sont en mesure de maximiser votre retour sur investissement. On y arrive grâce à notre expertise approfondie des plateformes.') }}
                    </p>
                    <a href="/services/publicite-en-ligne" class="cta full" v-if="$route.meta && $route.meta.lang === 'fr'"
                        ><p>{{ $t('En savoir plus') }}</p></a
                    >
                    <a href="/en/services/online-advertising" class="cta full" v-else
                        ><p>{{ $t('En savoir plus') }}</p></a
                    >
                </div>
            </div>

            <div class="bloc-img inverse">
                <div class="img" data-inview="revealLeft" data-delay="200">
                    <img
                        class="lazyload"
                        data-src="/static-assets/referencement.jpg"
                        alt="Site web"
                    />
                </div>
                <div class="data" data-inview="slideRight" data-delay="200">
                    <h3 class="regular-text">{{ $t('Référencement') }}</h3>
                    <h2 class="title">
                        {{ $t('Positionnez votre site en tête des résultats de recherche avec nos techniques naturelles et payantes.') }}
                    </h2>
                    <p class="regular-text">
                        {{ $t('Cette synergie des deux canaux permet de dominer la recherche pour passer devant la compétition. Ne sous-estimez pas son importance qui a des répercussions directes sur votre chiffre d’affaires. Faites appel à nos services et passez devant la compétition.') }}
                    </p>
                    <a href="/services/referencement" class="cta full" v-if="$route.meta && $route.meta.lang === 'fr'"><p>{{ $t('En savoir plus') }}</p></a>
                    <a href="/en/services/seo" class="cta full" v-else
                        ><p>{{ $t('En savoir plus') }}</p></a
                    >
                </div>
            </div>

            <div class="bloc-img">
                <div class="img" data-inview="revealRight" data-delay="200">
                    <img class="lazyload" data-src="/static-assets/site-web.jpg" alt="Site web" />
                </div>
                <div class="data" data-inview="slideLeft" data-delay="200">
                    <h3 class="regular-text">{{ $t('Conception Web') }}</h3>
                    <h2 class="title">
                        {{ $t('Créer un site web performant n’est pas un jeu d’enfant. Confiez votre projet à des professionnels.') }}
                    </h2>
                    <p class="regular-text">
                       {{ $t('La présence de votre entreprise sur le web passe plus que jamais par la création d’un site internet. Pour être efficace et générer des revenus, votre site web doit placer l’intérêt de vos clients au cœur de sa stratégie. Nous sommes là pour vous aider.') }}
                    </p>
                    <a href="/services/conception-web" class="cta full" v-if="$route.meta && $route.meta.lang === 'fr'"><p>{{ $t('En savoir plus') }}</p></a>
                    <a href="/en/services/web-design" class="cta full" v-else
                        ><p>{{ $t('En savoir plus') }}</p></a
                    >
                </div>
            </div>

            <img src="@/assets/img/half-circle-2.svg" class="circle-2" alt="Elmire logo" />
        </section>

        <section id="projets" class="realisations-wrap">
            <div class="title-intro">
                <p class="regular-text" data-inview="fadeInUp" data-delay="200">{{ $t('Nos projets') }}</p>
                <h2 class="title" data-inview="fadeInUp" data-delay="400">
                    <span v-html="$t('Nous considérons tous nos clients<br />comme des partenaires d’affaires')"></span>
                </h2>
            </div>
            <img src="@/assets/img/swipe.svg" class="swipe" alt="Swipe" />
            <div class="slider">
                <div class="box-wrap" data-inview="fadeInUp" data-delay="200">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Campagne de publicités en ligne') }}</p>
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Référencement Web') }}</p>
                            <p class="regular-text">{{ $t('Publicité YouTube') }}</p>
                            <p class="regular-text">{{ $t('Publicité Instagram') }}</p>
                            <p class="regular-text">{{ $t('Publicité Facebook') }}</p>
                        </div>
                        <img class="lazyload" data-src="/static-assets/fizz.jpg" alt="Fizz" />
                    </div>
                    <p class="regular-text name">Fizz</p>
                </div>
                <div class="box-wrap" data-inview="fadeInUp" data-delay="400">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Migration de site') }}</p>
                            <p class="regular-text">{{ $t('Accompagnement technique') }}</p>
                            <p class="regular-text">{{ $t('Stratégie de contenu') }}</p>
                            <p class="regular-text">{{ $t('Contrôle qualité') }}</p>
                            <p class="regular-text">{{ $t('Suivi des performances') }}</p>
                        </div>
                        <img class="lazyload" data-src="/static-assets/otogo-new.jpg" alt="Otogo" />
                    </div>
                    <p class="regular-text name">Otogo</p>
                </div>
                <div class="box-wrap" data-inview="fadeInUp" data-delay="600">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Stratégie média') }}</p>
                            <p class="regular-text">{{ $t('Campagne de publicités en ligne') }}</p>
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Publicité Facebook') }}</p>
                            <p class="regular-text">{{ $t('Publicité Instagram') }}</p>
                            <p class="regular-text">{{ $t('Référencement local') }}</p>
                        </div>
                        <img class="lazyload" data-src="/static-assets/avril.jpg" alt="Avril" />
                    </div>
                    <p class="regular-text name">Avril</p>
                </div>
                <div class="box-wrap" data-inview="fadeInUp" data-delay="800">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Campagne de publicités en ligne') }}</p>
                            <p class="regular-text">{{ $t('Publicités sociales') }}</p>
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Référencement Web') }}</p>
                            <!-- <p class="regular-text">Référencement payant</p> -->
                            <p class="regular-text">{{ $t('Analyse des contenus') }}</p>
                            <p class="regular-text">{{ $t('Page de destination') }}</p>
                        </div>
                        <img
                            class="lazyload"
                            data-src="/static-assets/tva-sports.jpg"
                            alt="TVA Sports"
                        />
                    </div>
                    <p class="regular-text name">TVA Sports</p>
                </div>
                <div class="box-wrap">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Campagne de publicités en ligne') }}</p>
                            <p class="regular-text">{{ $t('Publicités sociales') }}</p>
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Référencement Web') }}</p>
                            <!-- <p class="regular-text">Référencement payant</p> -->
                            <p class="regular-text">{{ $t('Optimisation des balises') }}</p>
                            <p class="regular-text">{{ $t('Migration de site') }}</p>
                            <p class="regular-text">{{ $t('Formation SEO') }}</p>
                        </div>
                        <img class="lazyload" data-src="/static-assets/qub.jpg" alt="QUB" />
                    </div>
                    <p class="regular-text name">QUB</p>
                </div>
                <div class="box-wrap">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Audit SEO') }}</p>
                            <p class="regular-text">{{ $t('Analyse des contenus') }}</p>
                            <!-- <p class="regular-text">Référencement payant</p> -->
                        </div>
                        <img
                            class="lazyload"
                            data-src="/static-assets/albi.jpg"
                            alt="Albi le géant"
                        />
                    </div>
                    <p class="regular-text name">Albi le géant</p>
                </div>
                <div class="box-wrap">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Audit SEO') }}</p>
                            <p class="regular-text">{{ $t('Référencement Web') }}</p>
                        </div>
                        <img
                            class="lazyload"
                            data-src="/static-assets/la-capitale.jpg"
                            alt="La Capitale"
                        />
                    </div>
                    <p class="regular-text name">La Capitale</p>
                </div>
                <div class="box-wrap">
                    <div class="img">
                        <div class="info">
                            <p class="regular-text">{{ $t('Référencement Web') }}</p>
                            <!-- <p class="regular-text">Référencement payant</p> -->
                            <p class="regular-text">{{ $t('Programmatique') }}</p>
                            <p class="regular-text">{{ $t('Publicité sociale') }}</p>
                            <p class="regular-text">{{ $t('Affiliation') }}</p>
                            <p class="regular-text">{{ $t('Marketing de contenu') }}</p>
                            <p class="regular-text">{{ $t('Commandite') }}</p>
                            <p class="regular-text">{{ $t('Référence local') }}</p>
                        </div>
                        <img class="lazyload" data-src="/static-assets/videotron.jpg" alt="OTOGO" />
                    </div>
                    <p class="regular-text name">Vidéotron</p>
                </div>
            </div>
        </section>

        <section id="equipe" class="team-wrap">
            <div class="title-wrap">
                <div class="title-intro">
                    <p
                        id="scrollerRefresh"
                        class="regular-text"
                        data-inview="fadeInUp"
                        data-delay="200"
                    >
                        {{ $t('Notre culture') }}
                    </p>
                    <h2 class="title" data-inview="fadeInUp" data-delay="400">
                        {{ $t('elmire, c’est une équipe de 30 personnes. 30 personnes qui partagent les mêmes valeurs et qui s’aident à évoluer au quotidien. C’est une équipe qui s’engage à offrir un milieu de travail diversifié, collaboratif et accueillant où chacun peut se sentir écouté.') }}
                    </h2>
                </div>
                <div class="lines">
                    <div class="line bleu"></div>
                    <div class="line jaune"></div>
                    <div class="line vert"></div>
                </div>
            </div>

            <div class="imgs-wrap">
                <div class="scroll-track">
                    <div class="auto-track">
                        <div class="img-wrap">
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-1.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-1.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-2.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-2.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-3.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-3.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-4.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-4.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-5.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-6.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-1.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-1.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-2.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-2.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-3.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-3.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-4.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img big">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-big-4.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                            <div class="img small">
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-5.jpg"
                                    alt="Elmire équipe"
                                />
                                <img
                                    class="lazyload"
                                    data-src="/static-assets/team-small-6.jpg"
                                    alt="Elmire équipe"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="team-data">
                <div class="data bleu" data-inview="fadeInUp" data-delay="200">
                    <h3 class="title small">
                        {{ $t('On vous donne accès à une tonne d’avantages sociaux.') }}
                    </h3>
                    <p class="regular-text">
                        {{ $t('Tel qu’un régime complet d’assurance collective compétitif, la possibilité d’adhérer à un programme de REER collectif et l’opportunité de profiter de rabais corporatifs chez plusieurs détaillants.') }}
                    </p>
                </div>
                <div class="data jaune" data-inview="fadeInUp" data-delay="400">
                    <h3 class="title small">
                        {{ $t('On a la chance de travailler sur une multitude de projets ambitieux et inspirants.') }}
                    </h3>
                    <p class="regular-text">
                        {{ $t('Des projets qui ont le potentiel de chambouler des industries en combinant nos expertises à la fine pointe et notre passion pour l’innovation.') }}
                    </p>
                </div>
                <div class="data vert" data-inview="fadeInUp" data-delay="600">
                    <h3 class="title small">
                        {{ $t('On vous offre toute la flexibilité dont vous avez besoin pour travailler, que ce soit au bureau ou à la maison.') }}
                    </h3>
                    <p class="regular-text">
                        {{ $t('Quand vous êtes au bureau, vous pouvez profiter de nos espaces modernes qui donnent sur Square-Victoria.  On a même une salle de gym que vous pouvez utiliser à tout moment et ce, sans frais!') }}
                    </p>
                </div>
            </div>
        </section>

        <section id="carrieres" class="jobs-wrap">
            <div class="title-intro">
                <p class="regular-text" data-inview="fadeInUp" data-delay="200">{{ $t('Carrières') }}</p>
                <h2 class="title" data-inview="fadeInUp" data-delay="400">
                    <span v-html="$t('On s’entoure des meilleurs<br />pour passer au niveau supérieur.')"></span>
                </h2>
            </div>
            <div class="jobs">
                <a href="https://careers.smartrecruiters.com/Videotron/elmire" target="_blank" class="cta full bigger"
                 data-inview="fadeInUp" data-delay="300"
                    ><p>{{ $t('Consultez nos différentes opportunités ici !') }}</p></a
                >
                <!-- <a
                    href="https://smrtr.io/bp4KC"
                    target="_blank"
                    class="job"
                    data-inview="fadeInUp"
                    data-delay="400"
                >
                    <p class="number">01</p>
                    <h3 class="title small">Stratège, Média numérique</h3>
                </a>
                <a
                    href="https://smrtr.io/bp4LN"
                    target="_blank"
                    class="job"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="number">02</p>
                    <h3 class="title small">Spécialiste, Média Numérique</h3>
                </a>
                <a
                    href="#"
                    target="_blank"
                    class="job"
                    data-inview="fadeInUp"
                    data-delay="600"
                    @click.prevent="showform"
                >
                    <p class="number">03</p>
                    <h3 class="title small">{{ $t('Candidature spontanée') }}</h3>
                </a> -->
            </div>
            <img src="@/assets/img/half-circle-3.svg" class="circle-3" alt="Elmire logo" />
        </section>

        <div class="overlay-form" :class="{ show: showFormJob }">
            <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideform" />
            <div class="form-wrap">
                <h3 class="title">{{ $t('Candidature spontanée') }}</h3>
                <FormJob :hideform="hideform" />
            </div>
            <div class="opacity" @click.prevent="hideform"></div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import FormJob from '@/components/FormJob'

gsap.registerPlugin(ScrollTrigger)

// Intro Animation Scripts
require('@/plugins/AnimationsLayer')

export default {
    name: 'Home',

    components: {
        FormJob,
    },

    data() {
        return {
            heroSliderPos: 1,
            maxHeroPos: 3,
            showFormJob: false,
        }
    },

    watch: {
        $route: {
            handler($route) {
                if ($route && $route.hash) {
                    setTimeout(() => {
                        const $zone = document.querySelector($route.hash.replace('a-', ''))
                        const topPos = $zone.getBoundingClientRect().top + window.scrollY
                        // this changes the scrolling behavior to "smooth"
                        window.scroll({
                            top: topPos,
                            behavior: 'smooth',
                        })
                    }, 1175)
                }
            },
            immediate: true,
        },
    },

    mounted() {
        setTimeout(() => {
            setInterval(() => {
                this.heroSliderPos++

                if (this.heroSliderPos > this.maxHeroPos) {
                    this.heroSliderPos = 1
                }

                document.querySelector('#intro .slides').classList.remove('pos1', 'pos2', 'pos3')
                document.querySelector('.rotator').classList.remove('pos1', 'pos2', 'pos3')
                document.querySelector('#intro .slides').classList.add(`pos${this.heroSliderPos}`)
                document.querySelector('.rotator').classList.add(`pos${this.heroSliderPos}`)
            }, 3755)

            gsap.to('.scroll-track', {
                scrollTrigger: {
                    trigger: '.scroll-track',
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: 1,
                    markers: false,
                },
                x: -1000,
                ease: 'none',
            })

            gsap.from('#scrollerRefresh', {
                scrollTrigger: {
                    trigger: '#scrollerRefresh',
                    start: 'top bottom',
                    onEnter: () => {
                        ScrollTrigger.refresh()
                    },
                },
            })
        }, 275)
    },

    methods: {
        hideform() {
            this.showFormJob = false
        },
        showform() {
            this.showFormJob = true
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/_variables.scss';

.hero {
    padding: 5vw;
    position: relative;
    z-index: 100;

    .slides {
        position: relative;
        width: 100%;
        height: 150px;
        margin-top: 1vw;
        margin-bottom: 200px;
    }

    .slides.pos1 {
        .title-wrap.pos1 {
            opacity: 1;
            transform: translateY(0) translateX(-50%);
            height: 0;
        }
    }

    .slides.pos2 {
        .title-wrap.pos2 {
            opacity: 1;
            transform: translateY(0) translateX(-50%);
            height: 0;
        }
    }

    .slides.pos3 {
        .title-wrap.pos3 {
            opacity: 1;
            transform: translateY(0) translateX(-50%);
            height: 0;
        }
    }

    .title-wrap {
        position: absolute;
        top: 0;
        left: 50%;

        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;

        opacity: 0;
        transform: translateY(10px) translateX(-50%);
        transition: opacity 0.65s ease 0s, transform 0.65s ease 0s;

        .title {
            font-size: 35px;
            line-height: 1.5;
        }

        .line {
            width: 80px;
            height: 5px;
            margin: 0 auto;
            margin-bottom: 15px;

            &.bleu {
                background-color: $color2;
            }
            &.vert {
                background-color: $color4;
            }
            &.jaune {
                background-color: $color3;
            }
        }

        .regular-text {
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    .img-wrap {
        width: 100%;
        position: relative;
        margin-top: 180px;

        .circle {
            position: absolute;
            top: -120px;
            left: 50%;
            transform: translate(-50%, 0);
            background-color: #fff;
            border-radius: 50%;
            padding: 15px;

            .rotator {
                display: block;
                position: relative;
                transform: rotate(0);
                transition: transform 0.65s ease 0s;

                &.pos1 {
                    transform: rotate(0);
                }

                &.pos2 {
                    transform: rotate(120deg);
                }

                &.pos3 {
                    transform: rotate(242deg);
                }
            }

            img {
                width: 230px;
            }
        }

        .desktop {
            display: block;
        }
        .mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 1000px) {
        .slides {
            margin-bottom: 0;
        }
        .title-wrap {
            max-width: 800px;
            .title {
                font-size: 30px;
            }
        }
        .img-wrap {
            .circle {
                padding: 10px;
                top: -100px;
                img {
                    width: 200px;
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .slides {
            margin-top: 10vw;
        }
        .title-wrap {
            max-width: 800px;
            .title {
                font-size: 24px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .slides {
            margin-top: 10vw;
            height: 30vw;
        }
        .title-wrap {
            padding: 0 5vw;
            .title {
                font-size: 20px;
            }
            .regular-text {
                font-size: 16px;
            }
        }
        .img-wrap {
            .circle {
                padding: 10px;
                top: -90px;
                img {
                    width: 140px;
                }
            }
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
    }
}

.title-intro {
    .regular-text {
        margin-bottom: 25px;
    }
}

.services-wrap {
    position: relative;
    width: 100%;
    padding: 10vw 5vw 1px 5vw;
    background-color: $color5;
    z-index: 10;
    margin-top: -200px;

    .circle {
        position: absolute;
        top: 0;
        right: 0;
        width: 200px;
    }

    .title-intro {
        padding-top: 100px;
    }

    .circle-2 {
        position: absolute;
        left: 0;
        bottom: -110px;
        width: 300px;
        z-index: 10;
    }

    @media only screen and (max-width: 1000px) {
        margin-top: -150px;
        .circle {
            top: 50px;
            width: 150px;
        }
        .circle-2 {
            display: none;
        }
    }

    @media only screen and (max-width: 800px) {
        margin-top: -100px;
        .circle {
            width: 100px;
        }
    }
}

.bloc-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4vw;
    margin-bottom: 8vw;
    position: relative;
    z-index: 100;

    &:last-child {
        margin-bottom: 0;
    }

    &.inverse {
        .img {
            order: 1;
        }
        .data {
            order: 0;
        }
    }

    .img {
        width: 50%;
        order: 0;
    }

    .data {
        width: 45%;
        order: 0;

        .regular-text {
            margin-bottom: 25px;
        }

        .title {
            margin-bottom: 25px;
        }
    }

    @media only screen and (max-width: 800px) {
        flex-wrap: wrap;
        .img {
            width: 100%;
            order: 0;
            margin-bottom: 30px;
        }
        .data {
            width: 100%;
            order: 1;
        }
        &.inverse {
            .img {
                order: 0;
            }
            .data {
                order: 1;
            }
        }
    }
}

.realisations-wrap {
    position: relative;
    width: 100%;
    padding: 6vw 0 10vw 5vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .swipe {
        width: 30px;
        margin-right: 5vw;
    }

    .title-intro {
        margin-bottom: 4vw;
        position: relative;
        z-index: 100;
    }

    .slider {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        padding-bottom: 25px;

        .box-wrap {
            width: 30%;
            min-width: 30%;
            margin-right: 20px;

            .img {
                position: relative;
                width: 100%;
                margin-bottom: 20px;

                .info {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $color3;
                    padding: 3vw;
                    opacity: 0;
                    transition: all 0.2s ease;

                    .regular-text {
                        opacity: 0;
                        margin-left: 20px;
                        transition: all 0.4s ease;
                    }
                }

                &:hover {
                    .info {
                        opacity: 1;

                        .regular-text {
                            opacity: 1;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .title-intro {
            margin-bottom: 6vw;
        }
        .slider {
            .box-wrap {
                width: 35%;
                min-width: 35%;
            }
        }
    }
    @media only screen and (max-width: 800px) {
        .title-intro {
            margin-bottom: 40px;
        }
        .slider {
            .box-wrap {
                width: 40%;
                min-width: 40%;

                .img {
                    .info {
                        display: none;
                    }
                    &:hover {
                        .info {
                            opacity: 0;

                            .regular-text {
                                opacity: 1;
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .swipe {
            width: 30px;
            margin-right: 5vw;
            margin-bottom: 20px;
        }
        .title-intro {
            margin-bottom: 3vw;
        }
        .slider {
            .box-wrap {
                width: 55%;
                min-width: 55%;
            }
        }
    }
    @media only screen and (max-width: 500px) {
        .slider {
            .box-wrap {
                width: 70%;
                min-width: 70%;
            }
        }
    }
}

.team-wrap {
    width: 100%;
    .title-wrap {
        padding: 0 0 5vw 5vw;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        .title-intro {
            width: 50%;
        }
    }
    .lines {
        width: 50%;
        max-width: 330px;
        display: flex;

        .line {
            width: 110px;
            height: 8px;

            &.bleu {
                background-color: $color2;
            }
            &.jaune {
                background-color: $color3;
            }
            &.vert {
                background-color: $color4;
            }
        }
    }
    .imgs-wrap {
        min-width: 100%;
        width: 100%;
        min-height: 45vh;
        position: relative;
        overflow: hidden;
    }

    .scroll-track {
        min-width: 100%;
        display: flex;
        align-items: flex-start;
    }

    .auto-track {
        min-width: 100%;
        position: relative;
        display: flex;
        align-items: flex-start;

        animation: infinityLeft 125s linear infinite;

        @media (max-width: 767px) {
            animation: infinityLeft 75s linear infinite;
        }
    }

    .img-wrap {
        min-width: 100vw;
        width: 100vw;
        display: flex;

        .img {
            width: 30vw;
            min-width: 30.5vw;
            margin: 0 0.5vw;

            img {
                width: 100%;
            }

            &.small {
                img {
                    margin-bottom: 1.5vw;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .team-data {
        padding: 5vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .data {
            position: relative;
            width: 29%;
            padding: 20px 0 20px 30px;

            .title {
                margin-bottom: 20px;
            }

            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            &.bleu {
                &:before {
                    background-color: $color2;
                }
            }
            &.jaune {
                &:before {
                    background-color: $color3;
                }
            }
            &.vert {
                &:before {
                    background-color: $color4;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .lines {
            max-width: 270px;
            .line {
                width: 90px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .lines {
            max-width: 210px;
            .line {
                width: 700px;
                height: 5px;
            }
        }
        .team-data {
            .data {
                width: 100%;
                padding: 30px 0 30px 30px;

                &:before {
                    width: 5px;
                }
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .title-wrap {
            .title-intro {
                width: 100%;
                padding-right: 10vw;
            }
            margin-bottom: 20px;
        }
        .lines {
            display: none;
        }
        .img-wrap {
            .img {
                width: 50vw;
                min-width: 50.5vw;
                margin: 0 1vw;

                img {
                    width: 100%;
                }

                &.small {
                    img {
                        margin-bottom: 2.5vw;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.jobs-wrap {
    width: 100%;
    padding: 5vw;
    position: relative;
    margin-bottom: 5vw;

    .jobs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2vw;

        .job {
            display: block;
            width: 48%;
            margin-top: 30px;

            .number {
                color: $color1;
                font-weight: bold;
                font-size: 24px;
                margin-bottom: 20px;
                position: relative;
                z-index: 100;

                &:before {
                    content: '';
                    display: block;
                    width: 80px;
                    height: 5px;
                    background-color: $color3;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    z-index: -1;
                    transition: all 0.2s ease;
                }
            }

            &:hover {
                .number {
                    &:before {
                        width: 160px;
                    }
                }
                .title {
                    color: $color2;
                }
            }

            .title {
                color: $color1;
                transition: all 0.2s ease;
            }
        }
    }

    .circle-3 {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 200px;
    }

    @media only screen and (max-width: 1000px) {
        .circle-3 {
            width: 150px;
        }
    }

    @media only screen and (max-width: 700px) {
        .jobs {
            flex-wrap: wrap;
            .job {
                width: 100%;
                margin-top: 40px;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .circle-3 {
            width: 100px;
        }
    }
}

@keyframes infinityLeft {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-189%, 0, 0);
    }
}
</style>
